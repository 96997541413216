@import '../fonts/inter.scss';
@import '../fonts/space-grotesk.scss';
@import 'constants.module.scss';
@import 'helpers.scss';
@import 'reset.scss';
@import 'particle.scss';

html {
    overflow-y: auto;
	overscroll-behavior-y: none;
	overscroll-behavior-x: none;
	box-sizing: border-box;
}
* {
    font-family: $family-primary;
}
body {
	margin: 0;
	font-weight: $weight-normal;
	overflow-x: hidden;
	background-color: $grey-lightest;
	color: $grey-dark;
}
#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	main {
		flex: 1;
		padding-top: 4.5rem;
	}
}
.reCaptchaHolder {
  	font-family: $family-primary;
}
.container {
	flex-grow: 1;
	margin: 0 auto;
	position: relative;
	width: auto;
	@media screen and (min-width: $desktop) and (max-width: $desktopMaxW){
		max-width: $desktop - $container-offset;
		padding-inline: 2rem;
	}
	@media screen and (min-width: $widescreen) and (max-width: $widescreenMaxW){
		max-width: $widescreen - $container-offset;
		padding-inline: 2rem;
	}
	@media screen and (min-width: $desktop) {
		max-width: $fullhd - $container-offset;
	}
}

.collapse-css-transition {
 	transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}