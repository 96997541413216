.has-cursor-pointer {
	cursor: pointer;
}
.is-family-secondary {
	font-family: $family-secondary;
}
.is-capitalize {
	text-transform: capitalize;
}
.is-uppercase {
	text-transform: uppercase;
}
.nowrap {
    white-space: nowrap;
}
.is-size-6-half {
	font-size: 0.875rem;
}
.is-size-4 {
	font-size: 1.5rem;
}
.is-size-5 {
	font-size: 1.25rem;
}
.is-size-5-half {
	font-size: 1.125rem;
}
.is-block {
	display: block;
}
.mx-auto {
	margin: 0 auto;
}
.m-t-0 {
	margin-top: 0;
}
.m-b-0 {
	margin-bottom: 0;
}
.m-l-0 {
	margin-left: 0;
}
.m-r-0 {
	margin-right: 0;
}
.m-t-1 {
	margin-top: 1rem;
}
.m-b-1 {
	margin-bottom: 1rem;
}
.m-l-1 {
	margin-left: 1rem;
}
.m-r-1 {
	margin-right: 1rem;
}
.m-t-2 {
	margin-top: 2rem;
}
.m-b-2 {
	margin-bottom: 2rem;
}
.m-l-2 {
	margin-left: 2rem;
}
.m-r-2 {
	margin-right: 2rem;
}
.m-l-half-rem {
	margin-left: 0.5rem;
}
.m-r-half-rem {
	margin-right: 0.5rem;
}
.is-marginless {
	margin: 0;
}
.w-100 {
	width: 100%;
}
.has-text-centered {
	text-align: center;
}
.has-text-centered-touch {
	@media all and (max-width: $tabletMaxW) {
		text-align: center;
	}
}
.has-text-right {
	text-align: right;
}
.has-text-weight-normal {
	font-weight: $weight-normal;
}
.has-text-weight-bold {
	font-weight: $weight-bold;
}
.is-relative {
	position: relative;
}
.is-flex {
	display: flex;
}
.flex-start {
	justify-content: flex-start !important;
}
.flex-end {
	justify-content: flex-end !important;
}
.align-center {
	align-items: center;
}
.align-start {
	align-items: flex-start;
}
.justify-center {
	justify-content: center !important;
}
.flex-column {
	flex-direction: column;
}
.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.justify-end {
	justify-content: flex-end;
}
.align-self-end {
	align-self: flex-end;
}
.row-wrap {
	flex-flow: row wrap;
}
.flex-justify-align {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-1 {
	flex: 1 0;
}
.flex-2 {
	flex: 2 0;
}
.flex-3 {
	flex: 3 0;
}
@keyframes spinAround {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.help {
	font-size: 0.875rem;
	margin-top: 0.25rem;
}
.has-text-danger {
	color: $danger;
}
.has-text-success {
	color: $success;
}
.has-text-blue {
	color: $blue;
}
.has-text-grey {
	color: $grey;
}
.field {
	&:not(:last-child) {
		margin-bottom: 0.75rem;
	}
	.label {
		margin-bottom: 0.5rem;
		display: block;
	}
	input, select, textarea {
		margin-bottom: 0;
	}
}
.is-hidden {
	display: none;
}
.is-hidden-touch {
	@media all and (max-width: $tabletMaxW) {
		display: none;
	}
}
.is-hidden-mobile {
	@media all and (max-width: $mobileMaxW) {
		display: none;
	}
}
.is-hidden-desktop {
	@media all and (min-width: $desktop) {
		display: none;
	}
}

.section-title {
	font-size: 1.75rem;
	margin-bottom: 1.25rem;
	color: $blue;
	font-family: $family-secondary;
	@media all and (max-width: $tabletMaxW) {
		font-size: 1.25rem;
		margin-bottom: 1rem;
	}
}

.section-subtitle {
	font-size: 1.125rem;
	@media all and (max-width: $tabletMaxW) {
		font-size: 1rem;
	}
}

.chip {
	padding: 0.5rem 0.75rem;
	color: $blue;
	background-color: $white;
	font-size: 1.125rem;
	width: fit-content;
	border-radius: $radius-large;
}
.link-underline {
	max-width: max-content;
	width: 100%;
	height: fit-content;
	min-width: min-content;
	position: relative;
	color: $grey-dark;
	font-weight: $weight-bold;
	transition: all 0.2s;
	cursor: pointer;
	&.has-text-blue {
		color: $blue;
	}
	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: inherit;
		height: 1px;
		border: 1px solid $blue;
		border-radius: 10%;
		opacity: 0;
		-webkit-transform: translateY(0.5rem);
		-ms-transform: translateY(0.5rem);
		transform: translateY(0.5rem);
		-webkit-transition: all 0.2s;
		transition: all 0.2s;
		box-sizing: border-box;
	}
	&:hover {
		color: $blue;
		&:after {
			opacity: 1;
			transform: translateY(0rem);
		}
	}
	&.is-active {
		color: $blue;
		&:after {
			opacity: 1;
		}
	}
}

.link-arrow {
	position: relative;
	font-weight: $weight-bold;
	color: $blue;
	display: inline-block;
	transition: all 0.5s;
	&:hover {
		&:after {
			max-width: 1.25rem;
			color: $blue;
			opacity: 1;
		}
	}
	&:after {
		content: '→';
		max-width: 0;
		opacity: 0;
		padding-left: 0.25rem;
		margin-right: -0.25rem;
		display: inline-block;
		transition: all 0.5s;
		vertical-align: top;
		white-space: nowrap;
		overflow: hidden;
	}	
}
.has-radius-100 {
	border-radius: 100%;
}
.has-background-blue {
	background-color: $blue;
}
.has-background-grey-light {
	background-color: $grey-light;
}
.has-text-grey-dark {
	background-color: $grey-dark;
}
.has-background-white {
	background-color: $white;
}
.has-gradient-background {
	background: radial-gradient(circle at bottom left, rgba(39,27,138, 0.35), 
	transparent 400px), radial-gradient(circle at top right, rgba(39,27,138, 0.35), 
	transparent 750px);
}

.has-gradient-background-desktop {
	@media all and (min-width: $desktop) {
		background: radial-gradient(circle at bottom left, rgba(39, 27, 138, 0.35),
			transparent 400px), radial-gradient(circle at top right, rgba(39, 27, 138, 0.35),
			transparent 750px);
	}
}

.is-expanded {
	.arrow {
		&:before {
			transform: rotate(-45deg);
		}
		&:after {
			transform: rotate(45deg);
		}
	}
}

.arrow {
	width: 1.5625rem;
	height: 0.75rem;
	position: absolute;
	top: 1rem;
	right: 0;
	margin-top: 0;
	&:before,
	&:after {
		display: block;
		position: absolute;
		top: 0;
		width: 0.625rem;
		height: 0.0625rem;
		background-color: currentColor;
		content: '';
		transition: transform 0.2s ease;
	}
	&:before {
		left: 0.25rem;
		transform: rotate(45deg);
	}
	&:after {
		right: 0.25rem;
		transform: rotate(-45deg);
	}
}


@keyframes delay-text {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}