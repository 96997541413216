h1, h2, h3 {
    margin: 0;
    font-weight: $weight-normal;
    font-size: 1rem;
}
p {
    margin-top: 0;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: $grey-dark;
}
ul {
    list-style: none;
    padding: 0;
}
input, textarea, select {
    &:not([type='checkbox']) {
        border-radius: $radius;
        font-family: $family-primary;
        font-size: 1rem;
        width: 100%;
        display: block;
        padding: 0.75rem 0.75rem;
        box-sizing: border-box;
        border: 1px solid $grey;
        margin-bottom: 0.5rem;
        color: $grey-dark;
        transition: all 0.2s;
    
        &:focus {
            outline: none;
        }
    
        &::placeholder {
            font-size: 1rem;
            color: $grey-lighter;
            transition: all 0.2s;
        }
    
        &.is-danger {
            border-color: $danger;
        }
    
        &:hover {
            &::placeholder {
                color: $grey;
            }
        }
    }
    
    select {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: none;
    }
}
.button {
    color: $white;
    background-color: $sea-green;
    border: none;
    font-weight: $weight-bold;
    border-radius: $radius-small;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s;
    height: fit-content;
    position: relative;
    &:hover {
        background-color: $dark-cyan;
    }
    &.is-small {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }
    &.is-loading {
        pointer-events: none;
        color: transparent;
        &:after {
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));
            animation: spinAround 0.5s linear infinite;
            border-radius: 290486px;
            border-color: transparent transparent $white !important;
            border-style: solid;
            border-width: 2px;
            content: "";
            display: block;
            height: 1em;
            width: 1em;
        }
    }
    &.has-arrow {
        transition: all 0.5s;
        &:hover {
            &:after {
                max-width: 1.25rem;
                color: white;
                opacity: 1;
            }
        }
        &:after {
            content: '→';
            max-width: 0;
            opacity: 0;
            padding-left: 0.25rem;
            margin-right: -0.25rem;
            display: inline-block;
            transition: all 0.5s;
            vertical-align: top;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}