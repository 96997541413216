$font-path: "/fonts/inter" !default;

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Inter Regular'),
    local('Inter-Regular'),
    url('#{$font-path}/Inter-Regular.woff2') format('woff2'),
    url('#{$font-path}/Inter-Regular.woff') format('woff')
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Inter Bold'),
    local('Inter-Bold'),
    url('#{$font-path}/Inter-Bold.woff2') format('woff2'),
    url('#{$font-path}/Inter-Bold.woff') format('woff')
}