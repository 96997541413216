$weight-normal: 400;
$weight-bold: 700;

$family-primary: 'Inter', sans-serif;
$family-secondary: 'Space Grotesk', sans-serif;

//Radiuses
$radius-extra-small: 5px;
$radius-small: 10px;
$radius: 15px;
$radius-large: 30px;


//Media Sizes
$gap: 32px;
$quad2Hd: 1682px;
$quadHd: 1442px;
$desktop: 1024px;
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$mobileMaxW: 767px;
$tablet: $mobileMaxW + 1;
$tabletMaxW: $desktop - 1px;
$desktopMaxW: $widescreen - 1px;
$widescreenMaxW: $fullhd - 1px;
$fullHdMaxW: $quadHd - 1px;
$quadHdMaxW: $quad2Hd - 1px;
$container-offset: (2 * $gap) !default;


//Colors
$white: #FFF;
$grey-lightest: #EFEFEF;
$grey-lighter: #E6E6E6;
$grey-light: #EFF1FF;
$grey: #A0A0A0;
$g: #9B9B9B;
$grey-dark: #414141;
$danger: #CC3300;
$blue: #27138B;
$dark-blue: #001055;
$sea-green: #138B63;
$success: #00A53F;
$dark-cyan: #138B7D;
$primary: $danger;

:export {
    white: $white;
    greyLightest: $grey-lightest;
    greyLighter: $grey-lighter;
    greyLight: $grey-light;
    grey: $grey;
    greyDark: $grey-dark;
    danger: $danger;
    success: $success;
    blue: $blue;
    darkBlue: $dark-blue;
    seaGreen: $sea-green;
    darkCyan: $dark-cyan;

    quadHD: $quadHd;
    quad2HD: $quad2Hd;
    mobileMaxW: $mobileMaxW;
    tablet: $tablet;
    tabletMinW: $mobileMaxW + 1;
    tabletMaxW: $tabletMaxW;
    desktop: $desktop;
    desktopMaxW: $desktopMaxW;
    widescreen: $widescreen;
    widescreenMaxW: $widescreenMaxW;
    fullhd: $fullhd;
    fullHdMaxW: $fullHdMaxW;
    quadHdMaxW: $quadHdMaxW;
    weightNormal: $weight-normal;
    weightBold: $weight-bold;
    radiusExtraSmall: $radius-extra-small;
    radiusSmall: $radius-small;
    radius: $radius;
    radiusLarge: $radius-large;

    familySecondary: $family-secondary;
};