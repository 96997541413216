$font-path-cookie: '/fonts/space-grotesk' !default;

@font-face {
	font-family: 'Space Grotesk';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('SpaceGrotesk Bold'),
	local('SpaceGrotesk-Bold'),
	url('#{$font-path-cookie}/SpaceGrotesk-Bold.woff') format('woff'),
}