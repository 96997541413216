/* Config */
$color-particle-green: #309a40;
$color-particle-blue: #102271;
$spacing: 2560px;
$spacing2: 1000px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max, $color) {
    $val: 0px 0px $color;

    @for $i from 1 through $max {
        $val: #{$val}, random($spacing)+px random($spacing)+px $color;
    }

    @return $val;
}

@mixin particles($max, $color) {
    box-shadow: particles($max, $color);
    border-radius: 50%;
}


/* Styles */


.particle {
    background: transparent;
    opacity: 0.75;
}

.particle-1 {
    animation: animParticle $time-1 linear infinite;
    @include particles(1000, $color-particle-blue);
    height: 3px;
    width: 3px;
}

.particle-2 {
    animation: animParticle2 $time-2 linear infinite;
    @include particles(800, $color-particle-green);
    height: 2px;
    width: 2px;
}

.particle-4 {
    animation: animParticle $time-4 linear infinite;
    @include particles(1200, $color-particle-blue);
    height: 2px;
    width: 2px;
}

@keyframes animParticle {
    from {
        transform: translate(0, 0);
    }

    to {
        transform: translate($spacing2, $spacing * -1);
    }
}

@keyframes animParticle2 {
    from {
        transform: translate(0, 0);
    }

    to {
        transform: translate($spacing * -1, $spacing * -1);
    }
}